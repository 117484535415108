<template>
  <CommonTab
    v-model="modelValue"
    class="mt-28px <sm:mt-10px @sm:mt-16px @md:mt-22px"
    :list="list"
    @change="changeTab"
  />
</template>

<script setup lang="ts">
const route = useRoute()
const router = useRouter()
const routeName = computed(() => route.name ?? "")
const modelValue = shallowRef(routeName.value)

interface ListSectionType {
  label: string
  icon: string
  value: RouteName
}
const list = computed(() => {
  const gameCategoriesMenu = gameCategoriesMenuState.gameCategoriesMenu.map(
    (item) => {
      return {
        label: item.lable,
        icon: item.icon,
        value: item.routeName,
      }
    }
  )
  return [
    { label: "Home", icon: "icons-sliderbar-home", value: "Home" },
    ...gameCategoriesMenu,
  ]
})

const changeTab = (item: ListSectionType) => {
  router.push({ name: item.value })
}
</script>

<style lang="scss" scoped>
@mixin active {
  color: var(--v-class-game-active-color);
  background: linear-gradient(180deg, #323443 0%, #262836 100%);
  box-shadow: 0px 2px 4px #1a1f26;
}
.Classification-inner {
  background: var(--v-class-inner-bg);
  width: fit-content;
  max-width: 100%;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .game-info {
    color: var(--v-class-game-color);
    &-active {
      @include active;
    }
    &:hover {
      @include active;
    }
  }
}
</style>
