<template>
  <div class="home-banner-area">
    <Swiper
      v-if="bannerList && bannerList.length"
      class="home-banner w-full overflow-hidden"
      :slides-per-view="1"
      :navigation="true"
      :loop="true"
      :autoplay="{
        disableOnInteraction: false,
      }"
      :pagination="{
        clickable: true,
      }"
      :modules="[Navigation, Pagination, Autoplay]"
    >
      <SwiperSlide
        v-for="item in bannerList"
        :key="`${item.gmaeId}-${item.slotId}`"
        class="h-full"
      >
        <CommonAsset
          class="w-full h-full object-center object-cover select-none"
          :class="{
            'cursor-pointer': item.status === 1 || item.status === null,
          }"
          :src="item?.img"
          :alt="`${item?.gameId}`"
          @click="onClick(item)"
        />
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script lang="ts" setup>
import { Swiper, SwiperSlide } from "swiper/vue"
import { Navigation, Pagination, Autoplay } from "swiper"
import { useUnLogin } from "~/composables/unLogin"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import { useHomeStore } from "~/stores/home"
import { onMounted } from "vue"
const router = useRouter()
const route = useRoute()
const HomeStore = useHomeStore()
const { handleUnLogin } = useUnLogin()

enum EnumJumpType {
  LINK_EXTERNAL = 1,
  LINK_GAME = 2,
}

const onClick = async (item: BannerType) => {
  const jumpType = Number(item.jumpType) as EnumJumpType
  if (isNaN(jumpType)) return

  switch (jumpType) {
    case EnumJumpType.LINK_EXTERNAL: {
      if (item.href && item.status === null) {
        // 判斷是否為外部連結，外部鏈結直接開啟新分頁，內部鏈結則使用 navigateTo。判斷方式用正則表達式
        const RegexAddress = /^(http|https):\/\/([\w.]+\/?)\S*/
        const RegexFullPath = /^\/([\w.]+\/?)\S*/
        if (RegexAddress.test(item.href)) {
          window.open(item.href, "_blank")
        }
        if (RegexFullPath.test(item.href)) {
          const url = new URL(`${item.href}`, window.location.origin)
          const routeDest = router.getRoutes().find((__route) => {
            if (new RegExp(`${__route.path}$`).test(url.pathname)) {
              return true
            }
            return false
          })
          if (routeDest) {
            await navigateTo({
              name: routeDest.name as RouteName,
              query: route.query,
            })
          } else {
            console.error("Cannot find route")
          }
        }
      }
    }
    case EnumJumpType.LINK_GAME:
    default: {
      if (item.gameId && Number(item.status) === 1) {
        if (await handleUnLogin()) return
        await navigateTo({
          name: "GameRoom",
          query: { gameId: item.slotId, gameProviderSubtypeId: item.gameId },
        })
      }
    }
  }
}

const device = computed(() => (useWindowSize().width.value < 768 ? "m" : "d"))

const bannerList = computed(() => {
  return HomeStore.bannerList?.filter((item) =>
    item.device ? item.device === device.value : true
  )
})

onMounted(() => {
  watch(
    device,
    () => {
      HomeStore.GET_BANNER()
    },
    { immediate: true }
  )
})
</script>

<style lang="scss" scoped>
.home-banner-area {
  .home-banner {
    aspect-ratio: 1920/500;
    @apply text-[18px] <sm:text-[10px] <md:text-[12px] <lg:text-[14px];
  }
  :deep(.swiper) {
    .swiper-button-next,
    .swiper-button-prev {
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.3);
      width: 26px;
      height: 26px;
      border-radius: 100%;
      &:after {
        font-size: 16px;
        font-weight: bold;
      }
    }
    .swiper-pagination-bullet-active {
      background-color: #fff;
      width: 10px;
      height: 10px;
    }
  }
}

@screen <md {
  .home-banner-area {
    .home-banner {
      aspect-ratio: 375/175;
    }
  }
}
</style>
