<template>
  <ul class="mt-32px <sm:mt-16px @sm:mt-16px @md:mt-22px">
    <template v-for="item in classificationList">
      <li
        v-if="!!item.gameResDtoList?.length"
        :key="item.dataAttrName"
        class="mb-36px <sm:mb-18px @sm:mb-22px @md:mb-28px @lg:mb-34px"
      >
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <CommonAsset
              v-if="item.logoUrl"
              class="w-24px mr-14px <sm:mr-10px"
              :src="item.logoUrl"
              alt="logo"
            />
            <p class="text-24px font-500 <sm:text-14px">
              {{
                t(`menu.${item.className?.charAt(0).toUpperCase() ?? ""}${
                  item.className?.slice(1) ?? ""
                }`)
              }}
            </p>
          </div>
          <div class="flex items-center">
            <button
              v-if="item.isMoreButtonShow"
              class="mr-15px border-[#546879] border-1px px-10px py-5px rounded-16px text-[#FFFFFF] text-16px <md:text-12px <md:py-4px <md:mr-10px"
              @click="handleMoreClick"
            >
              More
            </button>
            <div class="flex relative z-5">
              <div
                class="direction-item direction-item-left text-18px cursor-pointer <md:px-6px <md:py-3px px-10px py-5px border-right-none"
                :data-scroller-name="`${String(
                  item.dataAttrName
                )}-scroller-left`"
                @click="scrollTo('left', String(item.dataAttrName))"
              >
                <CommonAsset
                  class="svg-icon w-18px <md:w-16px"
                  name="icons-left-arrow"
                />
              </div>
              <div
                class="direction-item direction-item-right text-18px cursor-pointer <md:px-6px <md:py-3px p-10px py-5px"
                :data-scroller-name="`${String(
                  item.dataAttrName
                )}-scroller-right`"
                @click="scrollTo('right', String(item.dataAttrName))"
              >
                <CommonAsset
                  class="svg-icon w-18px <md:w-16px"
                  name="icons-right-arrow"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="relative">
          <div
            ref="GameContentEls"
            :data-scroll-name="String(item.dataAttrName)"
            class="game-list flex overflow-x-scroll pt-20px -mx-[0.6em] px-[0.6em] <md:-mx-[0.1em] <md:px-[0.1em]"
            :class="{
              [item.gameListClassName ?? '']: true,
              '!<md:-mx-[100%] !<md:px-[100%]': isCanBurdenMargin,
            }"
            @scroll="handleScrollBehavior"
          >
            <TransitionGroup enter-active-class="animated fadeIn">
              <component
                :is="item.Component"
                v-for="val in item.gameResDtoList"
                :key="val.id"
                ref="GameCard"
                :class="{
                  [item.gameCardClassName ?? '']: true,
                }"
                :ending-info="endingInfo"
                :item="val"
              />
            </TransitionGroup>
          </div>
        </div>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import { useHomeStore } from "~/stores/home"
import { useUserStore } from "~/stores/user"
import { useGame } from "~/stores/game"
import { useUnLogin } from "~/composables/unLogin"
import { ViewsHomeGameEnding, ViewsGameItem } from "#components"
const { t } = useI18n()
const router = useRouter()
const { IS_SHOW_ENDING } = useConfig()
const game = useGame()
const userStore = useUserStore()
const isLogin = computed(() => userStore.isLogin)
const endingInfo = computed(
  () => game.endingInfo as SpaceGameRelated.SlotGameList.GameItem
)
const endingList = computed(() => game.endingList) as Ref<EndingItem[]> // ending列表
const HomeStore = useHomeStore()
const { handleUnLogin } = useUnLogin()
const isCanBurdenMargin = ref(true)
type ClassificationType = {
  className: string
  gameResDtoList: EndingItem[]
  id?: string | number
  logoUrl?: string
  disabledLeft?: boolean
  disabledRight?: boolean
  sort?: number
  dataAttrName?: string
  Component: Component
  isMoreButtonShow: boolean
  gameCardClassName: "ViewsHomeGameEnding" | "ViewsGameItem"
  gameListClassName: "GameEndingList" | "GameList"
}

const GameContentEls = ref<HTMLDivElement[]>()
const classificationList = computed(() => {
  try {
    const insertInfo = {
      className: "SK Ending",
      gameResDtoList: endingList.value ?? [],
      dataAttrName: "SK Ending-99999",
      Component: ViewsHomeGameEnding,
      isMoreButtonShow: true,
      gameCardClassName: "ViewsHomeGameEnding",
      gameListClassName: "GameEndingList",
    }

    const classificationList = HomeStore.classificationList
    const mappedList = classificationList.map((item) => ({
      ...item,
      gameResDtoList: item.gameResDtoList ?? [],
      dataAttrName: `${item.className}-${item.id}`,
      Component: ViewsGameItem,
      isMoreButtonShow: false,
      gameCardClassName: "ViewsGameItem",
      gameListClassName: "GameList",
    }))

    const finalList = [insertInfo, ...mappedList]
    // IS_SHOW_ENDING后台开启  status==='1' ending游戏正常
    if (IS_SHOW_ENDING.value !== "1" || endingInfo.value?.status !== 1) {
      return mappedList as unknown as Partial<ClassificationType>[]
    }

    return finalList as Partial<ClassificationType>[]
  } catch (e) {
    console.error(e)
    return []
  }
})

function scrollTo(direction: "left" | "right", dataAttrName: string) {
  const scrollContent = GameContentEls.value?.find(
    (n) => n.getAttribute("data-scroll-name") === dataAttrName
  )
  const wrapperWidth = scrollContent?.parentElement?.clientWidth
  const card = scrollContent?.lastElementChild
  const cardStyle = window.getComputedStyle(card as Element)
  const distUnit =
    (wrapperWidth ?? 0) + Number(cardStyle.marginLeft.match(/\d+/)?.[0] ?? 0)
  switch (direction) {
    case "left":
      scrollContent?.scrollTo({
        left: scrollContent!.scrollLeft - (distUnit ?? 0),
        behavior: "smooth",
      })
      break
    case "right":
      scrollContent?.scrollTo({
        left: scrollContent!.scrollLeft + (distUnit ?? 0),
        behavior: "smooth",
      })
      break
  }
}

const handleMoreClick = async () => {
  try {
    if (await handleUnLogin()) return
    const { id, gameProviderSubtypeId, gameProviderId } = endingInfo.value
    router.push({
      name: "GameRoom",
      query: { gameId: id, gameProviderSubtypeId, gameProviderId },
    })
  } catch (e) {
    console.log(e)
  }
}

function handleScrollBehavior(event: Event) {
  try {
    const targetEl = event.target as HTMLElement
    const eadge = targetEl.scrollWidth - targetEl.clientWidth
    const scrollerRight = document.querySelector(
      `[data-scroller-name="${targetEl.getAttribute(
        "data-scroll-name"
      )}-scroller-right"]`
    ) as HTMLElement
    const scorllerLeft = document.querySelector(
      `[data-scroller-name="${targetEl.getAttribute(
        "data-scroll-name"
      )}-scroller-left"]`
    ) as HTMLElement
    if (targetEl.scrollLeft >= eadge - 10) {
      scrollerRight.setAttribute("data-disabled", "true")
    } else {
      scrollerRight.setAttribute("data-disabled", "false")
    }
    if (targetEl.scrollLeft <= 10) {
      scorllerLeft.setAttribute("data-disabled", "true")
    } else {
      scorllerLeft.setAttribute("data-disabled", "false")
    }
  } catch (error) {}
}

const checkPhoneType = () => {
  const isIphone = /iphone/gi.test(navigator.userAgent)
  if (isIphone) {
    const match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/)
    if (match !== null && match.length > 3) {
      const version = parseInt(match[1], 10)
      // ios 小於16的版本都不給用負margin，避免scrollwidth計算錯握
      if (version <= 15) isCanBurdenMargin.value = false
    }
  }
}

watch(
  isLogin,
  async () => {
    await Promise.all([
      game.GET_ENDING_RECOMMEND(),
      game.GET_ENDING(),
      HomeStore.GET_CLASSIFY_LIST(),
    ])
  },
  {
    immediate: true,
  }
)

onMounted(() => {
  checkPhoneType()
  GameContentEls.value?.forEach((n) => {
    handleScrollBehavior({ target: n } as unknown as Event)
  })
  watch(
    GameContentEls,
    (newVal) => {
      newVal?.forEach((n) => {
        handleScrollBehavior({ target: n } as unknown as Event)
      })
    },
    {
      deep: true,
    }
  )
})
</script>

<style lang="scss" scoped>
.game-list {
  --v-gap: 1em;
  --shadow-size: 0.3em;
  --shadow-size-hover: 0.6em;
  @apply text-10px <sm:text-8px <md:text-10px <lg:text-10px space-x-$v-gap -mb-2em pb-2em;
  .ViewsGameItem {
    width: calc((100% - var(--v-gap) * 7) / 8);
    @screen <lg {
      width: calc((100% - var(--v-gap) * 5) / 6);
    }
    @screen <md {
      width: calc((100% - var(--v-gap) * 3) / 4);
    }
    @screen <sm {
      width: calc((100% - var(--v-gap) * 2) / 3);
    }
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  > * {
    box-shadow: var(--shadow-size) var(--shadow-size) var(--shadow-size)
      rgba(0, 0, 0, 0.7);
    &:hover {
      --trans-size: calc(-1 * var(--shadow-size-hover));
      transform: translate(0, var(--trans-size));
      box-shadow: var(--shadow-size-hover) var(--shadow-size-hover)
        var(--shadow-size-hover) rgba(0, 0, 0, 0.7);
    }
    border-radius: 1.7em;
  }
}
.direction-item {
  border: 1px solid #576673;
  border-radius: 20px;
  &:hover {
    .svg-icon {
      transform: scale(1.2);
    }
  }
  &-left {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    //opacity: 0.5;
  }
  &-right {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }
  &[data-disabled="true"] {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.GameEndingList {
  --count: 4;
  @screen <xl {
    --count: 3;
  }
  @screen <lg {
    --count: 2;
  }
  > * {
    width: calc(
      (100% - (var(--count) - 1) * var(--v-gap)) / var(--count)
    ) !important;
    min-width: unset !important;
  }
}
</style>
