<template>
  <div
    class="ending-item rounded-8px flex-shrink-0 cursor-pointer flex flex-col <sm:w-49/100"
    @click="toGame"
  >
    <div
      class="ending-header flex justify-between pt-12px pb-8px px-16px <sm:pt-8px <sm:pb-6px <sm:px-6px"
    >
      <span class="text-14px font-600 <lg:text-12px <sm:text-10px">{{
        props.item.shortName
      }}</span>
      <div class="flex text-10px font-600 gap-5px <sm:hidden">
        <span>{{
          dayjs((props.item.startAt ?? Date.now() / 1000) * 1000).format(
            "MMM DD"
          )
        }}</span>
        <span>|</span>
        <span>{{
          dayjs((props.item.startAt ?? Date.now() / 1000) * 1000).format(
            "HH:mm A"
          )
        }}</span>
      </div>
      <p class="state-text hidden text-8px font-600 <sm:block">
        {{ props.item.state == 1 ? "UPCOMING" : "ONGOING" }}
      </p>
    </div>

    <div
      class="ending-content flex justify-between p-16px flex-1 <sm:flex-col <sm:px-6px <sm:py-8px"
    >
      <div
        class="hidden justify-between text-8px text-[#FFFFFF] opacity-60 font-600 mb-10px <sm:flex"
      >
        <span>{{
          dayjs((props.item.startAt ?? Date.now() / 1000) * 1000).format(
            "MMM DD"
          )
        }}</span>
        <span>
          {{
            dayjs((props.item.startAt ?? Date.now() / 1000) * 1000).format(
              "HH:mm A"
            )
          }}
        </span>
      </div>
      <div
        class="team-info w-[60%] flex items-center justify-around <sm:w-full"
      >
        <div class="w-[45%] h-full">
          <CommonAsset
            :src="props.item.hostLogo"
            class="team-info-logo"
            alt=""
          />
          <div class="name-outside">
            <p class="team-name">{{ props.item.hostTeam }}</p>
          </div>
        </div>
        <span class="team-vs">VS</span>
        <div class="w-[45%] h-full">
          <CommonAsset
            :src="props.item.awayLogo"
            class="team-info-logo"
            alt=""
          />
          <div class="name-outside">
            <p class="team-name">{{ props.item.awayTeam }}</p>
          </div>
        </div>
      </div>
      <span class="middle-line"></span>
      <div class="game-status flex-shrink-0">
        <p class="text-12px font-700 text-center <2xl:text-10px <sm:hidden">
          {{ props.item.state == 1 ? "UPCOMING" : "ONGOING" }}
        </p>
        <div
          class="bet-now py-16px px-26px cursor-pointer text-12px font-700 mt-9px <2xl:text-10px <2xl:py-12px <2xl:px-20px <sm:text-12px <sm:py-8px <sm:font-700"
        >
          BET NOW
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { dayjs } from "element-plus"
import { useUnLogin } from "~/composables/unLogin"

const { handleUnLogin } = useUnLogin()
const router = useRouter()
const props = withDefaults(
  defineProps<{
    item: EndingItem
    endingInfo: SpaceGameRelated.SlotGameList.GameItem
  }>(),
  {
    item() {
      return {}
    },
  }
)
const toGame = async () => {
  try {
    if (await handleUnLogin()) return
    const {
      endingInfo: { status, id: gameId, gameProviderSubtypeId },
    } = props

    if (status === 1)
      router.push({
        name: "GameRoom",
        query: { gameId, matchId: props.item.gameId, gameProviderSubtypeId },
      })
  } catch (e) {}
}
</script>
<style lang="scss" scoped>
@mixin active {
  .ending-header {
    background: rgba(10, 33, 77, 0.7);
  }
  .ending-content {
    background: linear-gradient(101.55deg, #121212 -5.3%, #0855af 104.26%);
  }
  .game-status {
    .bet-now {
      background: linear-gradient(
        165.47deg,
        #1999ee 9.53%,
        #117eff 40.97%,
        #0b3aa8 88.96%
      ) !important;
    }
  }
}
.ending-item {
  .ending-header {
    background: #082355;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px 8px 0px 0px;
    color: #fff;
    .state-text {
      line-height: 14px;
      @screen <sm {
        line-height: 8px;
      }
    }
  }
  .name-outside {
    @apply flex items-center justify-center min-h-20px;
    .team-name {
      @apply text-10px text-center font-500;
    }
  }
  .team-info-logo {
    @apply w-[60%] mx-auto mb-8px <xl:w-[50%] <sm:w-[38%];
    aspect-ratio: 1;
  }
  .ending-content {
    background: linear-gradient(180deg, #082355 -57.95%, #182631 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 0 0 8px 8px;
    .team-vs {
      background: linear-gradient(180deg, #93b7d3 -35.42%, #041535 100%);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      @apply py-4px px-10px text-12px font-700 rounded-4px mx-5px <2xl:px-8px <2xl:text-10px <sm:px-5px <sm:text-8px;
    }
    .middle-line {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    .game-status {
      @apply text-center <sm:pl-0 <sm:ml-0 <sm:border-0;
      .bet-now {
        background: linear-gradient(180deg, #93b7d3 -36.17%, #041534 100%);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }
    }
  }
  &:hover {
    @include active;
  }
  @screen <sm {
    @include active;
  }
}

@media (max-width: 500px) {
  .ending-item {
    .name-outside {
      min-height: 16px;
      .team-name {
        font-size: 8px;
      }
    }
  }
}
</style>
